import api from '@/plugins/api'

const medicalStaff = {
  load: async function () {
    try {
      const response = await api.get('/medical-staff')
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  create: async function (request) {
    try {
      const response = await api.post('/medical-staff', request)
      return response.data
    } catch (e) {
      console.error(e)
    }
    return null
  },
  update: async function (id, request) {
    try {
      const response = await api.patch(`/medical-staff/${id}`, request)
      return response.data
    } catch (e) {
      console.error(e)
    }
    return null
  },
  remove: async function (id) {
    try {
      await api.delete(`/medical-staff/${id}`)
      return true
    } catch (e) {
      console.error(e)
    }
    return false
  }
}

export default medicalStaff