var users = {
  methods: {
    isClinicDependant: function (userLevel) {
      switch (userLevel) {
        case 'clinic':
        case 'manager':
          return true;
      }
      return false;
    },
    isBusiness: function (userLevel) {
      return userLevel == 'business';
    },
    isAdmin: function (userLevel) {
      return userLevel == 'admin';
    },
    isClinicManager: function (userLevel) {
      return userLevel == 'manager';
    },
    isClinic: function (userLevel) {
      return userLevel == 'clinic';
    }
  }
}
export default users;