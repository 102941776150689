<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("medical-staff") }}</h2>
      <p>{{ $t("medical_staff.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12" md="7">
        <datatable :headers="headers" :table="entries">
          <template #[`item.name`]="{ item }">
            <span>{{ item.name }}</span>
            <span>{{ $t(item.position) }}</span>
          </template>
          <template #[`item.phone_number`]="{ item }">
            <span>{{ item.phone_number }}</span>
          </template>
          <template #[`item.recepcionist`]="{ item }">
            <span v-if="item.recepcionist">{{ `${$t("recepcionist")}` }}</span>
            <span v-if="item.unlocker">{{ `${$t("unlocker")}` }}</span>
          </template>
          <template #[`item.id`]="{ item }">
            <div class="svg-buttons" style="white-space: nowrap">
              <v-btn
                small
                icon
                color="success"
                class="pl-0 pr-0"
                @click.prevent="edit(item)"
              >
                <v-img max-width="24px" :src="require(`@/assets/edit.svg`)" />
              </v-btn>
              <v-btn
                small
                icon
                color="error"
                class="ml-2 pl-0 pr-0"
                @click.prevent="remove(item)"
              >
                <v-img max-width="24px" :src="require(`@/assets/delete.svg`)" />
              </v-btn>
            </div>
          </template>
        </datatable>
      </v-col>
      <v-col cols="12" md="5">
        <validation-observer v-slot="{ valid }">
          <v-form @submit.prevent="submit">
            <v-row no-gutters>
              <v-col>
                <h2 class="mb-2">{{ $t(`medical_staff.${formAction}`) }}</h2>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('fullname').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.name"
                    outlined
                    :label="$t('fullname')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('phone-number').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.phone_number"
                    outlined
                    :label="$t('phone-number')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('position').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="request.position"
                    outlined
                    :items="['doctor', 'nurse']"
                    :label="$t('position')"
                    :error-messages="errors"
                  >
                    <template #selection="{ item }">
                      {{ $t(item) }}
                    </template>
                    <template #item="{ item }">
                      {{ $t(item) }}
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-checkbox
                  v-model="request.recepcionist"
                  :label="$t('recepcionist')"
                  class="mt-0"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="request.unlocker"
                  :label="$t('unlocker')"
                  class="mt-0"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col v-if="request.id" cols="6">
                <v-btn
                  block
                  outlined
                  color="error"
                  elevation="0"
                  @click.prevent="resetForm"
                >
                  {{ $t("cancel") }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  elevation="0"
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";
import Datatable from "./components/Datatable";
import medicalStaff from "../requests/medicalStaff";

export default {
  mixins: [],
  components: {
    Heading,
    Datatable,
  },
  computed: {
    formAction: function () {
      return this.request.id === "" ? "new" : "edit";
    },
    headers: function () {
      return [
        {
          text: this.$t("fullname"),
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: this.$t("phone-number"),
          value: "phone_number",
          sortable: false,
        },
        {
          text: this.$t("capabilities"),
          value: "recepcionist",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          width: "20px",
        },
      ];
    },
  },
  data: () => ({
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    request: {
      id: "",
      name: "",
      phone_number: "",
      position: "doctor",
      unlocker: false,
      recepcionist: false,
    },
  }),
  watch: {},
  created: async function () {
    this.loadEntries();
  },
  methods: {
    loadEntries: async function () {
      const response = await medicalStaff.load();
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
    },
    submit: async function () {
      const response =
        this.request.id !== ""
          ? await medicalStaff.update(this.request.id, this.request)
          : await medicalStaff.create(this.request);
      if (response) {
        this.resetForm();
        this.loadEntries();
      }
    },
    resetForm: async function () {
      this.request = {
        id: "",
        name: "",
        phone_number: "",
        position: "doctor",
        unlocker: false,
        recepcionist: false,
      };
    },
    edit: async function (item) {
      this.request = Object.assign({}, item);
    },
    remove: async function (item) {
      if (await medicalStaff.remove(item.id)) {
        this.loadEntries();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
